<script lang="ts">
	import {services, industries, expertises, solutions} from '$lib/config/footer';

	import ClutchIcon from '$icons/clutch-icon.svelte';
	import FacebookIcon from '$icons/facebook-icon.svelte';
	import TwitterIcon from '$icons/twitter-icon.svelte';
	import LinkedinIcon from '$icons/linkedin-icon.svelte';
	import BehanceIcon from '$icons/behance-icon.svelte';
	import Logo from '$icons/exoft-white-logo.svelte';
	import GrowPadIcon from '$icons/growpad-logo.svelte';
</script>

<footer>
	<div class="container top">
		<div class="top-part">
			<div class="offices flex-column">
				<div class="logo">
					<a href="/" aria-label="Home">
						<Logo />
					</a>
				</div>
				
				<div class="list flex-column">
					<address>
						111A T. Shevchenko Str., Lviv Ukraine
					</address>
					<a href="tel:+380639631064">+38 063 963 1064</a>
					<a href="mailto:contactus@exoft.net">contactus@exoft.net</a>
				</div>
			</div>
			
			<nav>
				<div class="header">Services</div>
				{#each services as service}
					<a href="{service.url}">{service.text}</a>
				{/each}
			</nav>
			
			<nav>
				<div class="header">Expertises</div>
				{#each expertises as expertise}
					<a href="{expertise.url}">{expertise.text}</a>
				{/each}
				
				<div class="header">
					<a href="/technology/">Technology</a>
				</div>
			</nav>
			
		</div>
		
		<div class="top-part">
			<nav>
				<div class="header">Industries</div>
				{#each industries as industry}
					<a href="{industry.url}">{industry.text}</a>
				{/each}
			</nav>
			
			<nav>
				<div class="header">Solutions</div>
				{#each solutions as solution}
					<a href="{solution.url}">{solution.text}</a>
				{/each}
			</nav>
			
			<nav>
				<div class="header">About us</div>
				<a href="/about-us/">Who we are</a>
				<a href="/approach/">How we work</a>
				<a href="/our-clients/">Our clients</a>
				<a href="/our-team/">Our team</a>
				<div class="spacer"></div>
				<a class="header" href="/our-works/">Our works</a>
				
				<a class="header" href="/blog/">Blog</a>
				
				<a class="header" href="/career/">Career</a>
			</nav>
		</div>
	</div>

	<div class="container bottom">
		<div class="links flex-row">
			<div class="links-top">
				<div class="icons flex-row">
					<a
							href="https://www.linkedin.com/company/exoft"
							target="_blank"
							rel="noopener noreferrer nofollow">
						<LinkedinIcon />
					</a>
	
					<a
							href="https://twitter.com/ExoftSoftware"
							target="_blank"
							rel="noopener noreferrer nofollow">
						<TwitterIcon />
					</a>
	
	
					<a
							href="https://www.behance.net/exoft/"
							target="_blank"
							rel="noopener noreferrer nofollow">
						<BehanceIcon />
					</a>
	
					<a href="https://www.facebook.com/exoft" target="_blank" rel="noopener noreferrer nofollow">
						<FacebookIcon />
					</a>
	
					<a
							href="https://clutch.co/profile/exoft"
							target="_blank"
							rel="noopener noreferrer nofollow">
						<ClutchIcon />
					</a>
				</div>
				
				<div class="marketing flex-row">
					<a href="https://growpad.pro/ua/"
						 target="_blank"
						 rel="noopener noreferrer nofollow">
						<GrowPadIcon/>
					</a>
				</div>
			</div>
			
			<div class="credentials">
				<a href="/cookie-policy/">Cookie policy</a>
				<a href="/privacy-policy/">Privacy policy</a>
				<div>
					© 2013-2025 Exoft. All Rights Reserved
				</div>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	@import '$styles/vars.scss';

	footer {
		margin-top: 120px;
		color: $main-white;
		background-color: $second-black;
		a {
			&:hover {
			color: $green;
			}
		}

		.top {
			width: 100%;
			display: flex;
			flex-direction: column;
			row-gap: 36px;
			padding-top: 31px;
			box-sizing: border-box;
			
			&-part {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				column-gap: 30px;
				:last-child {
					flex-basis: 20%;
				}
			}

			nav {
				display: flex;
				flex-direction: column;
				row-gap: 12px;
				font-weight: 200;
				font-size: 14px;
				line-height: 22px;
				.spacer {
					height: 100%;
				}
				.header {
					font-weight: 800;
					font-size: 14px;
				}
			}
		}
		.bottom {
			width: 100%;
			background-color: $main-black;
		}
	}

	.links {
		width: 100%;
		height: 48px;
		justify-content: space-between;
		align-items: center;
		column-gap: 20px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;
		box-sizing: border-box;
		&-top {
			display: flex;
			justify-content: space-evenly;
			width: 100%;
			column-gap: 12px;
		}
		.icons {
			align-items: center;
			column-gap: 12px;
			a {
				border-radius: 6px;
				background-color: $second-black;
			}
			:global(svg) {
				padding: 6px;
				&:hover {
					fill: $green;

					:global(path) {
						fill: $green;
					}
				}
			}
		}
		.marketing {
			align-items: center;
			column-gap: 6px;
			a {
				display: inline-block;
				width: 95px;
				filter: grayscale(100%);
				&:hover {
					filter: none;
				}
			}
		}
	}

	.credentials {
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
		color: $main-white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 24px;
		width: 100%;
	}

	.offices {
		.list {
			font-size: 12px;
			font-weight: 600;
			line-height: 22px;
			margin-top: 31px;
			row-gap: 12px;
			address {
				font-style: normal;
			}
		}
	}
  @media (max-width: 660px) {
		.links {
			margin-top: 0;
			height: unset;
			padding: 12px 0;
			flex-direction: column;
			align-items: start;
			row-gap: 24px;
			&-top {
				justify-content: space-between;
			}
		}
	}

	@media (max-width: $portrait-phone-size) {
		footer {
			margin-top: 36px;
			.top{
				display: none;
			}
		}

		.links {
		
		}
	}
</style>
